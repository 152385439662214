import React, { useEffect, useState, useContext } from 'react';

import { useNavigate } from 'react-router-dom';

import { signIn, fetchAuthSession, resetPassword, confirmResetPassword } from 'aws-amplify/auth';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import PasswordStrengthBar from '../../components/PasswordStrengthBar';

import { UserLoginInputContext } from '../../contexts';

import { checkForEmailErrors } from '../../utils';

import useAsync from '../../hooks/useAsync';

import './ResetPassword.scss';

export default function ResetPassword() {
  const { userLoginInput, setUserLoginInput } = useContext(UserLoginInputContext);

  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [resetCodeHasBeenSent, setResetCodeHasBeenSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationEmail, setVerificationEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [successfulPasswordReset, setSuccessfulPasswordReset] = useState(false);

  const [signingIn, setSigningIn] = useState(false);

  const [{ loading: submittingReset }, sendResetCodeRequest] = useAsync();
  const [{ loading: resettingPassword }, resetPasswordRequest] = useAsync();
  const [, signInRequest] = useAsync();
  const [, fetchAuthSessionRequest] = useAsync();

  const nav = useNavigate();

  function checkEmailInput() {
    const { errorStatus, errorMessage } = checkForEmailErrors(userLoginInput.email);
    setEmailInputHasError(errorStatus);
    setEmailErrorMessage(errorMessage);
  }

  useEffect(() => {
    if (userLoginInput.email) checkEmailInput();

    // Retrieve reset password information
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const urlVerificationCode = urlParamsOnPgLoad.get('verificationCode');
    const urlVerificationEmail = urlParamsOnPgLoad.get('userEmail');
    if (urlVerificationCode) setVerificationCode(urlVerificationCode);
    if (urlVerificationEmail) setVerificationEmail(urlVerificationEmail);
  }, []);

  if (successfulPasswordReset) {
    return (
      <div className="ResetPassword">
        <div className="success-message">
          <div className="check-icon"><CheckRoundedIcon /></div>
          <span>Password successfully reset</span>
        </div>
        <Button
          className={`return-to-portal ${signingIn ? 'loading' : ''}`}
          onClick={() => {
            setSigningIn(true);
            signInRequest({
              promise: () => signIn({ username: verificationEmail, password: userLoginInput.password }),
              onSuccess: async () => {
                fetchAuthSessionRequest({
                  promise: () => fetchAuthSession(),
                  onSuccess: (cognitoResponse) => {
                    const userGroups = cognitoResponse.tokens.accessToken.payload['cognito:groups'] || [];
                    if (userGroups.includes('enterprise-users') || userGroups.includes('investor-users')) {
                      window.location.assign(process.env.REACT_APP_ENTERPRISE_HOSTNAME);
                    } else if (userGroups.includes('initio-admins')) {
                      window.location.assign(process.env.REACT_APP_ADMIN_HOSTNAME);
                    } else {
                      window.location.assign(process.env.REACT_APP_INITIO_HOSTNAME);
                    }
                  },
                });
              },
            });
          }}
        >
          {signingIn ? (
            <>
              <span className="dots-circle-spinner" />
              Navigating to portal...
            </>
          ) : 'Navigate to portal'}
        </Button>
      </div>
    );
  }

  if (verificationCode) {
    return (
      <form
        className="ResetPassword"
        onSubmit={(e) => {
          e.preventDefault();
          resetPasswordRequest({
            promise: () => confirmResetPassword({
              username: verificationEmail,
              newPassword: userLoginInput.password,
              confirmationCode: verificationCode,
            }),
            onSuccess: () => setSuccessfulPasswordReset(true),
          });
        }}
      >
        <h4>Reset password</h4>
        <h5>{verificationEmail}</h5>
        <TextField
          label="Password"
          className="password-field"
          type={showPassword ? 'text' : 'password'}
          value={userLoginInput.password}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, password: e.target.value.trim() })}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputLabelProps={{ shrink: true }}
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordStrengthBar
          passwordInput={userLoginInput.password}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />

        <Button
          className={`confirm-reset-btn ${resettingPassword ? 'loading' : ''}`}
          type="submit"
          disabled={passwordStrength === 'Weak'}
        >
          {resettingPassword ? (
            <>
              <span className="dots-circle-spinner" />
              Resetting...
            </>
          ) : 'Reset'}
        </Button>
        <Button
          className={`send-new-link ${submittingReset ? 'loading' : ''}`}
          onClick={() => {
            sendResetCodeRequest({
              promise: () => resetPassword({ username: verificationEmail }),
              onSuccess: () => setResetCodeHasBeenSent(true),
            });
          }}
        >
          {submittingReset ? (
            <>
              <span className="dots-circle-spinner" />
              Sending new reset link...
            </>
          ) : 'Send new reset link'}
        </Button>
        <Button
          className="back-to-login"
          onClick={() => nav('/')}
        >
          Remember your password? Login
        </Button>
      </form>
    );
  }

  if (resetCodeHasBeenSent) {
    return (
      <div className="ResetPassword">
        <MarkEmailReadOutlinedIcon className="email-sent-icon" />
        <h4>Reset link sent</h4>
        <p className="p-body-medium">{`We sent an email to ${userLoginInput.email || verificationEmail} with a link to reset your password.`}</p>
        <Button
          className={`send-new-link ${submittingReset ? 'loading' : ''}`}
          onClick={() => {
            sendResetCodeRequest({
              promise: () => () => resetPassword({ username: userLoginInput.email || verificationEmail }),
              onSuccess: () => setResetCodeHasBeenSent(true),
            });
          }}
        >
          {submittingReset ? (
            <>
              <span className="dots-circle-spinner" />
              Sending new reset link...
            </>
          ) : 'Send new reset link'}
        </Button>
        <Button
          className="back-to-login"
          onClick={() => {
            setUserLoginInput({ email: '', password: '' });
            nav('/');
          }}
        >
          Remember your password? Login
        </Button>
      </div>
    );
  }

  return (
    <form
      className="ResetPassword"
      onSubmit={(e) => {
        e.preventDefault();
        sendResetCodeRequest({
          promise: () => resetPassword({ username: userLoginInput.email }),
          onSuccess: () => setResetCodeHasBeenSent(true),
        });
      }}
    >
      <h4>Forgot password?</h4>
      <p>Enter your email address and we&apos;ll send you a link to reset your password.</p>
      <TextField
        label="Email"
        className="email-field"
        value={userLoginInput.email}
        error={emailInputHasError}
        onChange={(e) => setUserLoginInput({ ...userLoginInput, email: e.target.value.trim() })}
        onFocus={() => setEmailInputHasError(false)}
        onBlur={checkEmailInput}
        InputLabelProps={{ shrink: true }}
        helperText={emailInputHasError ? (
          <>
            <ErrorIcon />
            {emailErrorMessage}
          </>
        ) : ' '}
      />
      <Button
        type="submit"
        className={`confirm-reset-btn ${submittingReset ? 'loading' : ''}`}
      >
        {submittingReset ? (
          <>
            <span className="dots-circle-spinner" />
            Sending reset link...
          </>
        ) : 'Send reset link'}
      </Button>
      <Button
        className="back-link"
        onClick={() => nav('/')}
      >
        Back
      </Button>
    </form>
  );
}
