import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import SignupFlow from './SignUpFlow';

import './SignUp.scss';

export default function Signup() {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <main className="Signup">
      <h4>Sign up</h4>
      <span className="current-step">{`${currentStep} of 3`}</span>
      <LinearProgress variant="determinate" value={(currentStep / 3) * 100} />
      <SignupFlow currentStep={currentStep} setCurrentStep={setCurrentStep} />
    </main>
  );
}
