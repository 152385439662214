import React, { useState, useEffect, useContext } from 'react';
import { signIn, fetchAuthSession, resendSignUpCode } from 'aws-amplify/auth';
import { useNavigate, useLocation } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

import { checkForEmailErrors } from '../../utils';

import { UserLoginInputContext } from '../../contexts';

import useAsync from '../../hooks/useAsync';
import useFetch from '../../hooks/useFetch';

import './Login.scss';

export default function Login() {
  const { userLoginInput, setUserLoginInput } = useContext(UserLoginInputContext);

  const [verifiedEmail, setVerifiedEmail] = useState('');

  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [passwordInputHasError, setPasswordInputHasError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const [signingIn, setSigningIn] = useState(false);

  const [showLoginErrorHeader, setShowLoginErrorHeader] = useState(false);

  const [showInactivityMessage, setShowInactivityMessage] = useState(false);

  const [, signInRequest] = useAsync();
  const [, authSessionRequest] = useAsync();
  const [{ loading: sendingNewCode }, resendVerificationCodeRequest] = useAsync();
  const [{ loading: checkingIsEmailVerified }, emailVerifiedRequest] = useFetch();

  const nav = useNavigate();
  const location = useLocation();

  async function checkEmailInput() {
    if (!userLoginInput.email) return;
    const { errorStatus, errorMessage } = checkForEmailErrors(userLoginInput.email);
    if (!errorStatus) {
      emailVerifiedRequest({
        url: `/accounts/is-verified/${userLoginInput.email}`,
        useApiKey: true,
        onSuccess: (response) => {
          if (response.userExists && !response.isVerified) {
            setEmailInputHasError(true);
            setEmailErrorMessage(
              <span>
                Please verify your email address to proceed.&nbsp;
                <Button
                  className={`resend-code-error-link ${sendingNewCode ? 'loading' : ''}`}
                  onClick={() => {
                    resendVerificationCodeRequest({
                      promise: () => resendSignUpCode({ username: userLoginInput.email }),
                      onSuccess: () => {
                        setUserLoginInput({ email: '', password: '' });
                        nav('/verify-email', { state: { verificationCodeSent: true, emailToVerify: userLoginInput.email } });
                      },
                    });
                  }}
                >
                  {sendingNewCode ? (
                    <>
                      <span className="dots-circle-spinner" />
                      Resending code...
                    </>
                  ) : 'Resend code?'}
                </Button>
              </span>
            );
          }
        },
      });
    } else {
      setEmailInputHasError(true);
      setEmailErrorMessage(errorMessage);
    }
  }

  useEffect(() => {
    if (location.state?.verifiedEmail) {
      setVerifiedEmail(location.state?.verifiedEmail);
      setUserLoginInput({ ...userLoginInput, email: location.state?.verifiedEmail });
      window.history.replaceState({}, '');
    } else if (userLoginInput.email) {
      checkEmailInput();
    }

    setShowInactivityMessage(new URLSearchParams(window.location.search).get('inactivity') === 'true');
  }, []);

  function checkForPasswordErrors() {
    if (!userLoginInput.password) return;
    if (userLoginInput.password.length < 8) {
      setPasswordInputHasError(true);
      setPasswordErrorMessage(
        <span>
          The password you entered is incorrect. Password must be 8 characters minimum.&nbsp;
          <Button
            className="forgot-password-error-link"
            onClick={() => {
              setUserLoginInput({ ...userLoginInput, password: '' });
              nav('/reset-password');
            }}
          >
            Forgot password?
          </Button>
        </span>,
      );
    } else {
      setPasswordInputHasError(false);
      setPasswordErrorMessage('');
    }
  }

  async function handleLogin(event) {
    event.preventDefault();
    if (userLoginInput.email === '' || userLoginInput.password === '') {
      if (userLoginInput.email === '') {
        setEmailInputHasError(true);
        setEmailErrorMessage('Please enter your email address.');
      }
      if (userLoginInput.password === '') {
        setPasswordInputHasError(true);
        setPasswordErrorMessage('No password entered');
      }
      return;
    }

    if (passwordInputHasError || emailInputHasError || checkingIsEmailVerified) return;

    setSigningIn(true);

    signInRequest({
      promise: () => signIn({ username: userLoginInput.email, password: userLoginInput.password }),
      onSuccess: async () => {
        authSessionRequest({
          promise: () => fetchAuthSession(),
          onSuccess: (cognitoResponse) => {
            const userGroups = cognitoResponse.tokens.accessToken.payload['cognito:groups'] || [];
            if (userGroups.includes('enterprise-users') || userGroups.includes('investor-users')) {
              window.location.assign(process.env.REACT_APP_ENTERPRISE_HOSTNAME);
            } else if (userGroups.includes('initio-admins')) {
              window.location.assign(process.env.REACT_APP_ADMIN_HOSTNAME);
            } else if (userGroups.includes('portfolio-users')) {
              window.location.assign(process.env.REACT_APP_INITIO_HOSTNAME);
            } else {
              setShowLoginErrorHeader(true);
              setSigningIn(false);
            }
          },
        });
      },
      onError: () => {
        setShowLoginErrorHeader(true);
        setSigningIn(false);
      },
    });
  }

  function loginHeader() {
    if (showInactivityMessage) {
      return (
        <div className="inactivity-header">
          <UpdateIcon />
          <h4>You&apos;ve been logged out due to inactivity</h4>
          <p>All of your progress has been saved, log in to continue.</p>
        </div>
      );
    }
    if (verifiedEmail) {
      return (
        <>
          <h4>Log in to continue</h4>
          {!showLoginErrorHeader && (
            <div className="email-verified-header">
              <div className="check-icon">
                <CheckRoundedIcon />
              </div>
              <p>{`Great! Your email ${verifiedEmail} has been verified. Please enter your password to continue.`}</p>
            </div>
          )}
        </>
      );
    }
    return <h4>Login</h4>;
  }

  return (
    <main className="Login">

      {loginHeader()}

      {showLoginErrorHeader && (
        <div className="login-error-header">
          <WarningRoundedIcon />
          <p>
            We&apos;re having trouble signing you in. Please double check your email
            address and password are correct, then try again.
          </p>
        </div>
      )}

      <form onSubmit={(e) => handleLogin(e)}>
        <TextField
          label="Email"
          className="email-field"
          disabled={!!verifiedEmail}
          InputLabelProps={{ shrink: true }}
          value={userLoginInput.email}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, email: e.target.value.trim() })}
          onFocus={() => setEmailInputHasError(false)}
          onBlur={checkEmailInput}
          error={emailInputHasError}
          autoComplete="username"
          helperText={(
            <>
              {emailInputHasError ? (
                <>
                  <ErrorIcon />
                  {emailErrorMessage}
                </>
              ) : 'Use your company email address'}
              {checkingIsEmailVerified && <span className="dots-circle-spinner" />}
            </>
          )}
        />

        <TextField
          label="Password"
          className="password-field"
          type={showPassword ? 'text' : 'password'}
          value={userLoginInput.password}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, password: e.target.value.trim() })}
          onFocus={() => setPasswordInputHasError(false)}
          onBlur={checkForPasswordErrors}
          error={passwordInputHasError}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputLabelProps={{ shrink: true }}
          autoComplete="current-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          helperText={passwordInputHasError ? (
            <>
              <ErrorIcon />
              {passwordErrorMessage}
            </>
          ) : ' '}
        />

        <Button
          type="submit"
          className={`confirm-login-btn ${(signingIn || checkingIsEmailVerified) ? 'loading' : ''}`}
        >
          {signingIn ? (
            <>
              <span className="dots-circle-spinner" />
              Logging in...
            </>
          ) : checkingIsEmailVerified ? <span className="dots-circle-spinner" /> : 'Login'}
        </Button>
      </form>

      <div className="bottom-link-btns">
        <Button
          className="reset-password-link"
          onClick={() => {
            setUserLoginInput({ ...userLoginInput, password: '' });
            nav('/reset-password');
          }}
        >
          Forgot password?
        </Button>
        {!verifiedEmail && !showInactivityMessage && (
          <Button
            className="sign-up-link"
            onClick={() => nav('/signup', { state: { currentStep: 1 } })}
          >
            No account? Sign-up
          </Button>
        )}
      </div>
    </main>
  );
}
