
// anystring@anystring.anystring
const isAValidEmail = (str) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);

function checkForEmailErrors(email) {
  let errorStatus = true;
  let errorMessage = '';
  if ((!email.includes('@') || email.split('@').length > 2)) errorMessage = 'An email address must contain a single @';
  else if (!email.split('@')[1].includes('.')) errorMessage = 'The domain portion of the email address is invalid (the portion after @)';
  else if (!isAValidEmail(email)) errorMessage = 'Invalid email entered...';
  else errorStatus = false;
  return { errorStatus, errorMessage };
}

function clearAuthCookies() {
  localStorage.clear();
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim();
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=${process.env.REACT_APP_COOKIE_STORAGE_DOMAIN}`;
  }
}

export {
  isAValidEmail,
  checkForEmailErrors,
  clearAuthCookies,
};
