import { useState, useEffect, useContext } from 'react';

import { ErrorMessageContext } from '../contexts';

export default function useAsync(givenAsyncRequest = {}) {
  const [asyncRequest, setAsyncRequest] = useState(givenAsyncRequest);

  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const { showErrorMessage, setShowErrorMessage } = useContext(ErrorMessageContext);

  async function asyncCall() {
    setLoading(true);
    setError(false);
    setResponse(null);
    try {
      const res = await asyncRequest.promise();
      setResponse(res);
      if (asyncRequest.onSuccess) asyncRequest.onSuccess(res);
    } catch (e) {
      setError(true);
      if (asyncRequest.onError) asyncRequest.onError(e);
      else setShowErrorMessage(!showErrorMessage.includes(e.toString()) ? `${showErrorMessage}\n${e.toString()}` : showErrorMessage);
    } finally {
      setLoading(false);
      setAsyncRequest({});
      if (asyncRequest.onFinally) asyncRequest.onFinally();
    }
  }

  useEffect(() => { if (asyncRequest.promise && !loading) asyncCall(); }, [asyncRequest]);

  return [{ response, loading, error }, setAsyncRequest];
}
