import React, { useEffect } from 'react';
import LogRocket from 'logrocket';

import NavBar from './components/NavBar';
import RouterProvider from './Routes';
import AlertDisplay from './components/AlertDisplay';

import { clearAuthCookies } from './utils';

import './App.scss';

export default function App() {
  const useLogRocket = process.env.REACT_APP_USE_LOG_ROCKET === 'true';

  if (useLogRocket) LogRocket.init(process.env.REACT_APP_LOG_ROCKET_APP_ID, {
    rootHostname: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN,
    mergeIframes: true,
  });

  useEffect(() => clearAuthCookies(), []);

  return (
    <div className="App">
      <AlertDisplay />
      <NavBar />
      <RouterProvider />
    </div>
  );
}
