import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth';

import Button from '@mui/material/Button';
import LinkOffIcon from '@mui/icons-material/LinkOff';

import VerificationCodeSent from './VerificationCodeSent';

import { ErrorMessageContext } from '../../contexts';

import useFetch from '../../hooks/useFetch';
import useAsync from '../../hooks/useAsync';

import './VerifyEmail.scss';

export default function VerifyEmail() {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [isConfirmationInProgress, setConfirmationInProgress] = useState(false);

  const [currentUserEmail, setCurrentUserEmail] = useState('');

  const [codeIsExpired, setCodeIsExpired] = useState(false);

  const [newCodeHasBeenSent, setNewCodeHasBeenSent] = useState(false);

  const [{ loading: checkingIsEmailVerified }, emailVerifiedRequest] = useFetch();
  const [{ loading: confirmingSignUp }, confirmSignUpRequest] = useAsync();

  const [{ loading: sendingNewCode }, resendVerificationCodeRequest] = useAsync();

  const nav = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  function verifyEmail() {
    if (location.state?.verificationCodeSent) {
      window.history.replaceState({}, '');
      return;
    }
    const userEmail = searchParams.get('userEmail');
    const verificationCode = searchParams.get('verificationCode');
    setCurrentUserEmail(userEmail);
    emailVerifiedRequest({
      url: `/accounts/is-verified/${userEmail}`,
      useApiKey: true,
      onSuccess: (response) => {
        if (response.userExists && response.isVerified) {
          setConfirmationInProgress(false);
          nav('/', { state: { verifiedEmail: userEmail } });
        } else if (response.userExists === 0) {
          setShowErrorMessage('User doesn\'t exists');
        } else {
          confirmSignUpRequest({
            promise: () => confirmSignUp({ username: userEmail, confirmationCode: verificationCode }),
            onSuccess: () => nav('/', { state: { verifiedEmail: userEmail } }),
            onError: (e) => {
              // TODO Handle more possible errors
              if (e.toString().includes('ExpiredCodeException')) {
                setCodeIsExpired(true);
              } else {
                setShowErrorMessage(e.toString());
              }
            },
          });
        }
      },
    });
  }

  useEffect(() => verifyEmail(), []);

  useEffect(() => setConfirmationInProgress(confirmingSignUp || checkingIsEmailVerified), [confirmingSignUp, checkingIsEmailVerified]);

  if (location.state?.verificationCodeSent || newCodeHasBeenSent) return <VerificationCodeSent emailToVerify={location.state?.emailToVerify} />;

  return (
    <div className="VerifyEmail">
      {isConfirmationInProgress && <div className="loading-wrapper"><div className="dots-circle-spinner" /></div>}
      {!isConfirmationInProgress && codeIsExpired && (
        <div className="code-is-expired">
          <div className="top-icon">
            <LinkOffIcon />
          </div>
          <h4>Your verification link has expired</h4>
          <p>
            {`This verification link has expired for security purposes, but don't worry we can email a new verification link to ${currentUserEmail}.`}
          </p>
          <Button
            className={`send-new-link ${sendingNewCode ? 'loading' : ''}`}
            onClick={() => {
              resendVerificationCodeRequest({
                promise: () => resendSignUpCode({ username: currentUserEmail }),
                onSuccess: () => {
                  setNewCodeHasBeenSent(true);
                  setCodeIsExpired(false);
                },
              });
            }}
          >
            {sendingNewCode ? (
              <>
                <span className="dots-circle-spinner" />
                Sending new verification link..
              </>
            ) : 'Send new verification link'}
          </Button>
          <Button className="login-link" onClick={() => nav('/')}>
            Already have an account? Login
          </Button>
        </div>
      )}
    </div>
  );
}
