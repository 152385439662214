import React from 'react';

import IconButton from '@mui/material/IconButton';

import { ReactComponent as InitioLogo } from '../images/initio-logo.svg';

import './NavBar.scss';

export default function NavBar() {
  return (
    <div className="NavBar">
      <IconButton
        className="initio-logo"
        onClick={() => window.location.assign(process.env.REACT_APP_AUTH_HOSTNAME)}
      >
        <InitioLogo />
      </IconButton>
    </div>
  );
}
