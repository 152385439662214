import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { resendSignUpCode } from 'aws-amplify/auth';

// MUI
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

// Icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

// Other Imports
import PasswordStrengthBar from '../../components/PasswordStrengthBar';
import { UserLoginInputContext, NavigationContext } from '../../contexts';

import { checkForEmailErrors } from '../../utils';

import useFetch from '../../hooks/useFetch';
import useAsync from '../../hooks/useAsync';

import './SignUpFlow.scss';

export default function SignUpFlow({ currentStep, setCurrentStep }) {
  const { userLoginInput, setUserLoginInput } = useContext(UserLoginInputContext);

  const { to, from } = useContext(NavigationContext);

  // Step #1
  const [emailInputHasError, setEmailInputHasError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');

  const [showEmailVerifiedErrorHeader, setShowEmailVerifiedErrorHeader] = useState(false);

  // Step #2
  const [companyName, setCompanyName] = useState('');
  const [companyNameInputHasError, setCompanyNameInputHasError] = useState(false);

  // Step #3
  const [sendNewsletterIsChecked, setSendNewsletterIsChecked] = useState(false);
  const [userAgreementIsChecked, setUserAgreementIsChecked] = useState(false);
  const [showUserAgreementError, setShowUserAgreementError] = useState(false);

  const [{ loading: signingUp }, signUpRequest] = useFetch();

  const [{ loading: checkingIsEmailDuplicate }, duplicateEmailRequest] = useFetch();
  const [{ loading: checkingIsEmailVerified }, emailVerifiedRequest] = useFetch();

  const [{ loading: sendingNewCode }, sendNewCodeRequest] = useAsync();

  const nav = useNavigate();

  // Handles browser navigation
  function handleNav(event) {
    if (!event) return;
    if (!event.state) return;
    if (event.state.currentStep === 2) {
      if (!userLoginInput.email) {
        setEmailInputHasError(true);
        setEmailErrorMessage('Please enter your email address.');
        return;
      }
      if (emailInputHasError) return;
    }
    if (event.state.currentStep === 3) {
      if (!companyName) {
        setCompanyNameInputHasError(true);
        return;
      }
    }
    setCurrentStep(event.state.currentStep);
  }

  useEffect(() => handleNav(to), [to]);
  useEffect(() => handleNav(from), [from]);

  function sendVerificationCode() {
    sendNewCodeRequest({
      promise: () => resendSignUpCode({ username: userLoginInput.email }),
      onSuccess: () => {
        setUserLoginInput({ email: '', password: '' });
        nav('/verify-email', { state: { verificationCodeSent: true, emailToVerify: userLoginInput.email } });
      },
    });
  }

  function checkEmailInput() {
    if (!userLoginInput.email) return;
    const { errorStatus, errorMessage } = checkForEmailErrors(userLoginInput.email);
    setEmailErrorMessage(errorMessage);
    if (!errorStatus) {
      duplicateEmailRequest({
        url: `/accounts/duplicates/${userLoginInput.email}`,
        useApiKey: true,
        onSuccess: (responseData) => {
          if (responseData.isAlreadyUser) {
            emailVerifiedRequest({
              url: `/accounts/is-verified/${userLoginInput.email}`,
              useApiKey: true,
              onSuccess: (response) => {
                if (!response.isVerified) {
                  setEmailInputHasError(true);
                  setShowEmailVerifiedErrorHeader(true);
                  setEmailErrorMessage(
                    <span>
                      Must verify email address.&nbsp;
                      <Button
                        className={`resend-code-error-link ${sendingNewCode ? 'loading' : ''}`}
                        onClick={sendVerificationCode}
                      >
                        {sendingNewCode ? (
                          <>
                            <span className="dots-circle-spinner" />
                            Resending verification code...
                          </>
                        ) : 'Resend verification code'}
                      </Button>
                    </span>
                  );
                } else {
                  setEmailInputHasError(true);
                  setEmailErrorMessage(
                    <span>
                      This email is already associated with an account.&nbsp;
                      <Button
                        className="forgot-password-error-link"
                        onClick={() => {
                          setUserLoginInput({ ...userLoginInput, password: '' });
                          nav('/reset-password');
                        }}
                      >
                        Forgot password?
                      </Button>
                    </span>
                  );
                }
              },
            });
          }
        },
      });
    } else {
      setEmailInputHasError(true);
      setEmailErrorMessage(errorMessage);
    }
  }

  useEffect(() => { if (userLoginInput.email) checkEmailInput(); }, []);

  function handleSubmit(event) {
    event.preventDefault();

    checkEmailInput();

    if (!userAgreementIsChecked) {
      setShowUserAgreementError(true);
      return;
    }

    const acctData = {
      email: userLoginInput.email,
      password: userLoginInput.password,
      companyName,
      isClient: 1,
      marketingOptIn: sendNewsletterIsChecked,
      isAuthSignatory: true,
    };

    signUpRequest({
      url: '/accounts/create-account',
      useApiKey: true,
      body: acctData,
      onSuccess: () => {
        setUserLoginInput({ email: '', password: '' });
        nav('/verify-email', { state: { verificationCodeSent: true, emailToVerify: userLoginInput.email } });
      },
    });
  }

  if (currentStep === 1) {
    return (
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!userLoginInput.email) {
            setEmailInputHasError(true);
            setEmailErrorMessage('Please enter your email address.');
            return;
          }
          if (emailInputHasError) return;
          setCurrentStep(currentStep + 1);
          nav('/signup', { state: { currentStep: currentStep + 1 } });
        }}
      >
        {showEmailVerifiedErrorHeader && emailInputHasError && (
          <div className="email-verified-error-header">
            <WarningRoundedIcon />
            <p>
              This email address is already associated with an account,
              but the email address hasn&apos;t been verified.
              To proceed,&nbsp;
              <Button
                className={`resend-code-error-link in-header ${sendingNewCode ? 'loading' : ''}`}
                onClick={sendVerificationCode}
              >
                resend verification code
              </Button>
              &nbsp;to this email address.
            </p>
          </div>
        )}
        <TextField
          label="Email"
          value={userLoginInput.email}
          error={emailInputHasError}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, email: e.target.value.trim() })}
          onFocus={() => setEmailInputHasError(false)}
          onBlur={checkEmailInput}
          InputLabelProps={{ shrink: true }}
          autoComplete="username"
          helperText={(
            <>
              {emailInputHasError ? (
                <>
                  <ErrorIcon />
                  {emailErrorMessage}
                </>
              ) : 'Use your company email address'}
              {(checkingIsEmailDuplicate || checkingIsEmailVerified) && <span className="dots-circle-spinner" />}
            </>
          )}
        />

        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={userLoginInput.password}
          onChange={(e) => setUserLoginInput({ ...userLoginInput, password: e.target.value.trim() })}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputLabelProps={{ shrink: true }}
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <PasswordStrengthBar
          passwordInput={userLoginInput.password}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />

        <Button
          type="submit"
          variant="contained"
          className={`continue-button ${checkingIsEmailDuplicate || checkingIsEmailVerified ? 'loading' : ''}`}
        >
          {checkingIsEmailDuplicate || checkingIsEmailVerified ? <span className="dots-circle-spinner" /> : 'Continue'}
        </Button>
        <Button
          className="login-btn"
          onClick={() => nav('/')}
        >
          Already have an account? Login
        </Button>
      </form>
    );
  }

  if (currentStep === 2) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!companyName) {
            setCompanyNameInputHasError(true);
            return;
          }
          setCurrentStep(currentStep + 1);
          nav('/signup', { state: { currentStep: currentStep + 1 } });
        }}
      >
        <TextField
          label="Company name"
          value={companyName}
          error={companyNameInputHasError}
          onChange={(e) => setCompanyName(e.target.value)}
          onFocus={() => setCompanyNameInputHasError(false)}
          helperText={companyNameInputHasError ? (
            <>
              <ErrorIcon />
              Please enter your company name
            </>
          ) : 'Please use your company\'s full legal name'}
        />
        <Button type="submit" className="continue-button">
          Continue
        </Button>
        <Button
          className="back-btn"
          onClick={() => {
            setCurrentStep(currentStep - 1);
            nav('/signup', { state: { currentStep: currentStep - 1 } });
          }}
        >
          Back
        </Button>
      </form>
    );
  }

  if (currentStep === 3) {
    return (
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="checkbox email-marketing">
          <Checkbox
            checked={sendNewsletterIsChecked}
            onChange={() => setSendNewsletterIsChecked(!sendNewsletterIsChecked)}
            icon={<div className="unchecked-icon" />}
            checkedIcon={(
              <div className="checked-icon">
                <CheckRoundedIcon />
              </div>
            )}
          />
          <span className="email-marketing-text">
            Yes, email or text me so that I can receive the
            latest news and information about events,
            products and services from initio.
          </span>
        </div>
        <div className="checkbox user-agreement-and-privacy">
          <Checkbox
            checked={userAgreementIsChecked}
            onChange={() => {
              setUserAgreementIsChecked(!userAgreementIsChecked);
              setShowUserAgreementError(false);
            }}
            icon={<div className="unchecked-icon" />}
            checkedIcon={(
              <div className="checked-icon">
                <CheckRoundedIcon />
              </div>
            )}
          />
          <span className="user-agreement-and-privacy-text">
            By creating and signing in to an initio account,
            I acknowledge that I have reviewed the initio
            <br />
            <a
              className="conditions-link"
              href="https://initio-frontend-assets.s3.us-west-2.amazonaws.com/Initio_Software_Inc.-Terms_and_Conditions.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions for Services
              {' '}
              <OpenInNewIcon />
            </a>
            {' '}
            and agree to be bound by the terms and conditions therein.
          </span>
        </div>
        {showUserAgreementError && (
          <div className="user-agreement-error">
            <ErrorIcon />
            You must accept the &lsquo;Terms and Conditions for Services&rsquo; to proceed.
          </div>
        )}

        <Button
          type="submit"
          className={`sign-up-button ${signingUp ? 'loading' : ''}`}
        >
          {signingUp ? (
            <>
              <span className="dots-circle-spinner" />
              Signing up...
            </>
          ) : 'Sign up'}
        </Button>
        <Button
          className="back-btn"
          onClick={() => {
            setCurrentStep(currentStep - 1);
            nav('/signup', { state: { currentStep: currentStep - 1 } });
          }}
        >
          Back
        </Button>
      </form>
    );
  }
}

SignUpFlow.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
};
