import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import {
  UserLoginInputContext,
  ErrorMessageContext,
} from '.';

export default function ContextWrapper({ children }) {
  const [userLoginInput, setUserLoginInput] = useState({ email: '', password: '' });
  const [showErrorMessage, setShowErrorMessage] = useState('');

  const userLoginInputProviderValue = useMemo(() => ({ userLoginInput, setUserLoginInput }), [userLoginInput]);
  const showErrorMessageProviderValue = useMemo(() => ({ showErrorMessage, setShowErrorMessage }), [showErrorMessage]);

  return (
    <UserLoginInputContext.Provider value={userLoginInputProviderValue}>
      <ErrorMessageContext.Provider value={showErrorMessageProviderValue}>
        {children}
      </ErrorMessageContext.Provider>
    </UserLoginInputContext.Provider>
  );
}

ContextWrapper.propTypes = { children: PropTypes.element.isRequired };
