import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { signIn, fetchAuthSession, signUp } from 'aws-amplify/auth';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import PasswordStrengthBar from '../../components/PasswordStrengthBar';

import useFetch from '../../hooks/useFetch';
import useAsync from '../../hooks/useAsync';

import './EnterpriseSignUp.scss';

export default function EnterpriseSignUp({ userType }) {
  const [userData, setUserData] = useState({});
  const [userPassword, setUserPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [passwordCapsLockIsOn, setPasswordCapsLockIsOn] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('Weak');

  const [finalizingUser, setFinalizingUser] = useState(false);

  const [, updateEnterpriseUserRequest] = useFetch();

  const [, signUpRequest] = useAsync();
  const [, firstSignInRequest] = useAsync();
  const [, authSessionRequest] = useAsync();
  const [, finalSignInRequest] = useAsync();


  useEffect(() => {
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const email = urlParamsOnPgLoad.get('email');
    const firstName = urlParamsOnPgLoad.get('firstName');
    const lastName = urlParamsOnPgLoad.get('lastName');
    const phoneNumber = urlParamsOnPgLoad.get('phoneNumber');
    const companyId = urlParamsOnPgLoad.get('companyId');
    setUserData({
      email,
      firstName,
      lastName,
      phoneNumber,
      companyId,
    });
  }, []);

  async function finalizeEnterpriseUser(event) {
    event.preventDefault();
    setFinalizingUser(true);
    signUpRequest({
      promise: () => signUp({
        username: userData.email, password: userPassword,
        options: {
          userAttributes: {
            email: userData.email,
            'custom:firstName': userData.firstName,
            'custom:lastName': userData.lastName,
            'custom:phoneNumber': userData.phoneNumber,
            'custom:userType': userType,
            'custom:companyId': userData.companyId,
            'custom:firstTimeExp': userType === 'enterprise' ? '1' : '0',
          },
        },
      }),
      onSuccess: () => {
        firstSignInRequest({
          promise: () => signIn({ username: userData.email, password: userPassword }),
          onSuccess: () => {
            authSessionRequest({
              promise: () => fetchAuthSession(),
              onSuccess: (authSession) => {
                const { sub } = authSession.tokens.idToken.payload;
                const accountData = {
                  ...userType === 'enterprise' && {
                    enterpriseCompanyId: userData.companyId,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNumber: userData.phoneNumber,
                  },
                  accountId: sub,
                  email: userData.email,
                  companyId: userData.companyId,
                };
                updateEnterpriseUserRequest({
                  url: `/accounts/update-verified-${userType}-account`,
                  body: accountData,
                  enterpriseCall: true,
                  onSuccess: async () => {
                    finalSignInRequest({
                      promise: () => signIn({ username: userData.email, password: userPassword }),
                      onSuccess: () => {
                        window.location.assign(
                          `${process.env.REACT_APP_ENTERPRISE_HOSTNAME}${userType === 'enterprise' ? '/first-enterprise-sign-in' : ''}`
                        );
                      },
                    });
                  },
                });
              },
            });
          },
        });
      },
    });
  }

  return (
    <main className="EnterpriseSignUp">
      <h4>Create password to continue</h4>
      <div className="success-header">
        <div className="check-icon">
          <CheckRoundedIcon />
        </div>
        <p>Great, let&apos;s get started! Simply create a password to login.</p>
      </div>
      <form onSubmit={(e) => finalizeEnterpriseUser(e)}>
        <TextField
          label="Email"
          className="email-field"
          value={userData.email || ''}
          InputLabelProps={{ shrink: true }}
          disabled
        />
        <TextField
          label="Password"
          className="password-field"
          type={showPassword ? 'text' : 'password'}
          value={userPassword || ''}
          onChange={(e) => setUserPassword(e.target.value)}
          onKeyDown={(e) => setPasswordCapsLockIsOn(e.getModifierState('CapsLock'))}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {passwordCapsLockIsOn && <KeyboardCapslockIcon />}
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <PasswordStrengthBar
          passwordInput={userPassword}
          passwordStrength={passwordStrength}
          setPasswordStrength={setPasswordStrength}
        />
        <Button
          type="submit"
          className={`confirm-login-btn ${finalizingUser ? 'loading' : ''}`}
          disabled={passwordStrength === 'Weak'}
        >
          {finalizingUser ? (
            <>
              <span className="dots-circle-spinner" />
              Logging in...
            </>
          ) : 'Login'}
        </Button>
      </form>
    </main>
  );
}

EnterpriseSignUp.propTypes = { userType: PropTypes.string.isRequired };
