import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';

import useFetch from '../../hooks/useFetch';
import useInterval from '../../hooks/useInterval';

import './VerificationCodeSent.scss';

export default function VerificationCodeSent({ emailToVerify }) {
  const [, emailVerifiedRequest] = useFetch();

  const nav = useNavigate();

  useInterval(() => {
    emailVerifiedRequest({
      url: `/accounts/is-verified/${emailToVerify}`,
      useApiKey: true,
      onSuccess: (response) => {
        if (response.userExists && response.isVerified) {
          nav('/', { state: { verifiedEmail: emailToVerify } });
        }
      },
    });
  }, 5000);

  return (
    <div className="VerificationCodeSent">
      <MarkEmailReadOutlinedIcon className="top-icon" />
      <h4>Verification email sent</h4>
      <p>{`To complete the sign up process you must verify your email address. We have sent a verification email to ${emailToVerify}.`}</p>
    </div>
  );
}

VerificationCodeSent.propTypes = { emailToVerify: PropTypes.string.isRequired };
