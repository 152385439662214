import { createContext } from 'react';

const ErrorMessageContext = createContext();
const UserLoginInputContext = createContext();
const NavigationContext = createContext();

export {
  UserLoginInputContext,
  ErrorMessageContext,
  NavigationContext,
};
