import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import { CookieStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import NavigationProvider from './contexts/NavigationProvider';
import ContextProvider from './contexts/ContextProvider';

import App from './App';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage({ domain: process.env.REACT_APP_COOKIE_STORAGE_DOMAIN }));

// MUI Global Style Alterations
const muiGlobalTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={muiGlobalTheme}>
        <NavigationProvider>
          <ContextProvider>
            <App />
          </ContextProvider>
        </NavigationProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
);
