import { useState, useContext, useEffect } from 'react';

import { ErrorMessageContext } from '../contexts';

import useAsync from './useAsync';

export default function useFetch(givenOptions = {}) {
  const [options, setOptions] = useState(givenOptions);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [status, setStatus] = useState(null);

  const { showErrorMessage, setShowErrorMessage } = useContext(ErrorMessageContext);

  const [, fetchRequest] = useAsync();
  const [, parseJsonRequest] = useAsync();

  function makeCall() {
    setCallEnded(false);
    setSuccess(false);
    setError(false);
    setResponse(null);

    setLoading(true);

    const body = options.body && Object.keys(options.body).length ? options.body : null;

    const fetchOptions = ({
      method: options.method || !!options.body ? 'post' : 'get',
      headers: { 'Content-Type': 'application/json' },
      ...body && { body: JSON.stringify(body) },
    });

    const backendUrl = options.enterpriseCall ? process.env.REACT_APP_ENTERPRISE_BACKEND_URL : process.env.REACT_APP_BACKEND_URL;

    const fetchUrl = `${backendUrl}${options.url}${options.useApiKey ? `?access_token=${process.env.REACT_APP_BACKEND_API_KEY}` : ''}`;

    fetchRequest({
      promise: () => fetch(fetchUrl, fetchOptions),
      onSuccess: (fetchData) => {
        setStatus(fetchData.status);
        if (fetchData.status >= 400) {
          setError(true);
          if (options.onError) options.onError();
          else {
            const errorMessage = `error with call: ${options.url}, error code: ${fetchData.status}`;
            setShowErrorMessage(!showErrorMessage.includes(errorMessage) ? `${showErrorMessage}\n${errorMessage}` : showErrorMessage);
          }
        } else if (fetchData.status === 204) {
          setSuccess(true);
          if (options.onSuccess) options.onSuccess(null, 204);
        } else {
          parseJsonRequest({
            promise: () => fetchData.json(),
            onSuccess: (parsedJsonData) => {
              setResponse(parsedJsonData);
              if (!options.multipleCalls || options.multipleCalls === 1) {
                setSuccess(true);
                if (options.onSuccess) options.onSuccess(parsedJsonData, fetchData.status);
              }
            },
          });
        }
      },
      onError: (e) => {
        setError(true);
        if (options.onError) options.onError();
        else setShowErrorMessage(!showErrorMessage.includes(e.toString()) ? `${showErrorMessage}\n${e.toString()}` : showErrorMessage);
      },
    });
  }

  useEffect(() => { if (options.url && !loading) makeCall(); }, [options]);

  useEffect(() => { if (error || success) setCallEnded(true); }, [error, success]);

  useEffect(() => {
    if (callEnded) {
      if (options.onFinally) options.onFinally();
      setOptions({});
      setLoading(false);
    }
  }, [callEnded]);

  return [{ response, loading, success, callEnded, status }, setOptions];
}
